.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* STYLES RESPONSIVE */

@media(max-width: 768px)  {

  .bgServiceProdu{
    background-size: auto !important;
    margin-bottom: 8rem;
  }
}

@media(max-width: 1023px)  {

  .teamResponsive{
    display: grid;
  }

  .teamResponsiveOrder1{
    order: 1;
  }

  .teamResponsiveOrder0{
    order: 0;
  }

  .bRadius0{
    border-radius: 0px !important;
  }

  .width50{
    width: 50%;
  }
  
  .md_text-center{
    text-align: center !important;
  }
}

@media(min-width: 768px) and (max-width: 1023px)  {
  .btnLearnMoreResponsive {
    display: flex !important;
    align-items: center !important;
    gap: 2;
  }


  .p0{
    padding: 0px !important;
  }

  .m0{
    margin: 0px !important;
  }

  .bgServiceProdu{
    background-size: auto !important;
  }
}


@media(max-width: 992px)  {
  .contactResponsive{
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media(min-width: 545px) and (max-width: 992px)  {

  .tabsTeamResponsive{
    display: grid !important;
    grid-template-columns: 40% 40% !important;
    gap: 10px 20px !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .w100md{
    width: 100%;
  }

  .ttnCompanyDiv { 
    order: 3;
    margin-top: 25px;
    
   }
  
  .ttnCompanyDiv div{ 
    margin: 0px !important
   }


}

@media(min-width: 993px)  {
  .orderLg1{
    order: 1;
  }
  
  .orderLg0{
    order: 0;
  }
}