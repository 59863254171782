@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

body {
  font-family: "Open Sans", sans-serif;
}

.bannerHome {
  height: 130vh;
  background-image: url("assets/bannerHome.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.bannerAbout {
  height: 100vh;
  background-image: url("assets/bannerAbout.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.bannerProducts {
  height: 100vh;
  background-image: url("assets/bannerProducts.svg");
  background-repeat: no-repeat;
  background-size: cover;
}
/* .bannerBiowel {
  height: 140vh;
  background-image: url("assets/bgLinkProducts.svg");
  background-image: url("assets/prueba1.svg");
  background-image: url("assets/prueba2.svg");
  background-repeat: no-repeat;
  background-size: cover;
} */
.bannerBiowel1 {
  height: 110vh;
  background-image: url("assets/prueba1.svg");
  background-repeat: no-repeat;
  background-size: cover;
}
/* .bannerBiowel2 {
  height: 100vh;
  background-image: url("assets/prueba2.svg");
  background-repeat: no-repeat;
  background-size: cover;
} */

.bannerService {
  height: 100vh;
  background-image: url("assets/bannerService.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.bgServiceProdu {
  /* height: 90vh; */
  background-image: url("assets/bgServiPro.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.bannerWorkWithUs {
  height: 100vh;
  background-image: url("assets/bannerWorkWithUs.png");
  background-repeat: no-repeat;
  background-size: cover;
}

i,
.maq {
  color: #003264;
  font-style: normal;
}

.colorPrimary {
  color: #01a0f6;
}

.prussianBlue {
  color: #01a0f6;
}

.colorSecundary {
  color: #003264;
}

.colorYellow {
  color: #ffb703;
}

.colorGray {
  color: #888faa;
}

.prussianBlueDark {
  color: #005BB5;
}

.textDarkGray{
  color: #58595B;
}
.textClearGray{
  color: #7E7E7E;
}


.Onyx {
  color: #404041;
}

.PrussianBlue120 {
  color: #001e3c;
}

.textAreaForm {
  background: #ffffff;
  border: 1px solid #d0d0d0;
  border-radius: 15px;
  outline: none;
}


.inputFormContact {
  background: #ffffff;
  border: 1px solid #a0a0a0;
  border-radius: 5px;
}

.inputFormContact::placeholder {
  color: #a0a0a050;
  font-size: 14px;
}

.selectFormContact {
  background: #ffffff;
  border: 1px solid #a0a0a0;
  border-radius: 5px;
}

.selectFormContact::placeholder {
  color: #a0a0a050;
  font-size: 14px;
}

.w-30 {
  width: 30%;
}

.w-90 {
  width: 90%;
}

.w-70 {
  width: 55%;
}

.fs-15 {
  font-size: 15px;
}

.fs-16 {
  font-size: 16px;
}

.fs-16-10 {
  font-size: 16px;
}

.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}

.fs-22 {
  font-size: 22px;
}

.fs-24 {
  font-size: 24px;
}
.fs-24-18 {
  font-size: 24px;
}

.fs-26 {
  font-size: 26px;
}
.fs-26-20 {
  font-size: 26px;
}

.fs-26-12{
  font-size: 26px;
}

.fs-32 {
  font-size: 32px;
}
.fs-32-30 {
  font-size: 32px;
}
.fs-32-16 {
  font-size: 32px;
}

.fs-36 {
  font-size: 36px;
}

.fs-38{
  font-size: 38px;
}

.fs-40 {
  font-size: 40px;
}

.fs-40-18 {
  font-size: 40px;
}
.fs-40-24 {
  font-size: 40px;
}

.fs-42 {
  font-size: 42px;
}

.fs-48 {
  font-size: 48px;
}

.fs-54 {
  font-size: 54px;
}

.fs-60 {
  font-size: 60px;
}
.fs-60-24 {
  font-size: 60px;
}

.fs-64 {
  font-size: 64px;
}

.fs-96 {
  font-size: 96px;
}
.fs-96-80 {
  font-size: 96px;
}

.pl-3 {
  padding-left: 1rem;
}

.pl-4 {
  padding-left: 1.5rem;
}

.py5 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.pb-6 {
  padding-bottom: 6rem;
}

.ml-3 {
  margin-left: 1rem;
}

.ml-5 {
  margin-left: 3rem;
}

.ml-10 {
  margin-left: 5rem;


}

.mr-3 {
  margin-right: 3rem;
}

.mt-3 {
  margin-top: 40px;
}

.vh-100 {
  height: 100vh;
}

.vh-90 {
  height: 90vh;
}

.btnSecundary {
  border-radius: 7px;
  border: 2px solid #01a0f6;
  width: max-content;
}

.btnSecundary:hover {
  border: 2px solid #ffb703;
  color: #001e3c;
}

.btnContact {
  border-radius: 7px;
  border: 2px solid #01a0f6;
  /* width: max-content; */
  width: 200px;
}

.btnContact:hover {
  color: #fff;
  background-color: #0783ff;
}

.arrowLeft {
  width: 10px;
  height: 10px;
  background-image: url("assets/arrowLeftBlue.svg");
  background-repeat: no-repeat;
  background-position: center;
  filter: brightness(0) saturate(100%) invert(35%) sepia(92%) saturate(2342%) hue-rotate(195deg) brightness(101%) contrast(101%);
}

.btnSecundary:hover>.arrowLeft {
  background-image: url("assets/arrowLeftYello.svg");
}

.btnImgCall {
  width: 10px;
  height: 10px;
  background-image: url("assets/callBlue.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.btnContact:hover>.btnImgCall {
  background-image: url("assets/callWhite.svg");
}

.sectionSecundary {
  margin-top: -40px;
  border-radius: 50px;
}

.imgBR {
  border-radius: 20px 0px 0px 20px;
}

.imgBRL300 {
  border-radius: 300px 0% 0% 300px;
}

.imgBRL300Woman {
  border-radius: 0% 300px 300px 0%;
}

.text-justify {
  text-align: justify;
}

.containerImg {
  box-shadow: 0px 4px 24px 8px rgba(78, 167, 205, 0.21);
  border-radius: 24px;
}

.fwBold{
  font-weight: 600;
}



.cardItem {
  min-height: 272px;
  max-height: 272px;
  min-width: 200px;
  max-width: 200px;
  background-color: #ffffff60;
  margin: 10px auto;
  box-shadow: 0px 1px 11px 2px rgba(78, 167, 205, 0.21);
  border-radius: 14px;
}

.cardItem:hover {
  /* margin: 50px auto; */
  /* width: 250px; */
  /* height: 250px; */
  /* border-radius: 14px #3ACFD5; */
  border-top: 3px solid #005DBF;
  border-bottom: 3px solid #01A0F6;
  /* -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box; */
  /* box-sizing: border-box; */
  background-position: 0 0, 100% 0;
  background-repeat: no-repeat;
  -webkit-background-size: 3px 100%;
  -moz-background-size: 3px 100%;
  background-size: 3px 100%;
  /* background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMSAxIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48bGluZWFyR3JhZGllbnQgaWQ9Imxlc3NoYXQtZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPjxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiMzYWNmZDUiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzNhNGVkNSIgc3RvcC1vcGFjaXR5PSIxIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2xlc3NoYXQtZ2VuZXJhdGVkKSIgLz48L3N2Zz4=),url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMSAxIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48bGluZWFyR3JhZGllbnQgaWQ9Imxlc3NoYXQtZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPjxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiMzYWNmZDUiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzNhNGVkNSIgc3RvcC1vcGFjaXR5PSIxIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2xlc3NoYXQtZ2VuZXJhdGVkKSIgLz48L3N2Zz4=); */
  /* background-image: -webkit-linear-gradient(top, #3acfd5 0%, #3a4ed5 100%), -webkit-linear-gradient(top, #3acfd5 0%, #3a4ed5 100%);
  background-image: -moz-linear-gradient(top, #3acfd5 0%, #3a4ed5 100%), -moz-linear-gradient(top, #3acfd5 0%, #3a4ed5 100%);
  background-image: -o-linear-gradient(top, #3acfd5 0%, #3a4ed5 100%), -o-linear-gradient(top, #3acfd5 0%, #3a4ed5 100%);
  background-image: linear-gradient(to bottom, #3acfd5 0%, #3a4ed5 100%), linear-gradient(to bottom, #3acfd5 0%, #3a4ed5 100%); */
  background-image: -webkit-linear-gradient(top, #005DBF 0%, #01A0F6 100%), -webkit-linear-gradient(top, #005DBF 0%, #01A0F6 100%);
  background-image: -moz-linear-gradient(top, #005DBF 0%, #01A0F6 100%), -moz-linear-gradient(top, #005DBF 0%, #01A0F6 100%);
  background-image: -o-linear-gradient(top, #005DBF 0%, #01A0F6 100%), -o-linear-gradient(top, #005DBF 0%, #01A0F6 100%);
  background-image: linear-gradient(to bottom, #005DBF 0%, #01A0F6 100%), linear-gradient(to bottom, #005DBF 0%, #01A0F6 100%);
}

/* .cardItem:hover{
  border-radius: 25px;
  border: 2px solid #73AD21;
  padding: 20px;
  background: linear-gradient(45deg,red,blue) border-box;
} */



.cardSlider {
  width: 100%;
  height: 100%;
  background-color: #ffffff60;
  margin: 10px auto;
  background: #ffffff;
  box-shadow: -2px 6px 13px rgba(1, 162, 245, 0.21);
  border-radius: 19px;
}

.cardAboutItem {
  min-height: 200px;
  max-height: 200px;
  min-width: 200px;
  max-width: 200px;
  background-color: #ffffff60;
  margin: 0 auto;
  box-shadow: 0px 1px 11px 2px rgba(78, 167, 205, 0.21);
  border-radius: 14px;
}

.cardAboutItem:hover {
  border-top: 3px solid #005DBF;
  border-bottom: 3px solid #01A0F6;
  background-position: 0 0, 100% 0;
  background-repeat: no-repeat;
  -webkit-background-size: 3px 100%;
  -moz-background-size: 3px 100%;
  background-size: 3px 100%;
  background-image: -webkit-linear-gradient(top, #005DBF 0%, #01A0F6 100%), -webkit-linear-gradient(top, #005DBF 0%, #01A0F6 100%);
  background-image: -moz-linear-gradient(top, #005DBF 0%, #01A0F6 100%), -moz-linear-gradient(top, #005DBF 0%, #01A0F6 100%);
  background-image: -o-linear-gradient(top, #005DBF 0%, #01A0F6 100%), -o-linear-gradient(top, #005DBF 0%, #01A0F6 100%);
  background-image: linear-gradient(to bottom, #005DBF 0%, #01A0F6 100%), linear-gradient(to bottom, #005DBF 0%, #01A0F6 100%);
}

.bgFrom {
  /* height: 90vh; */
  background-image: url("assets/bgForm.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.bgAccordionMis {
  padding: 5rem 3rem 5rem 3rem;
  background-image: url("assets/bannerAccorMis.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.bgAccordionvis {
  padding: 5rem 3rem 5rem 3rem;
  border-radius: 14px;
  background-image: url("assets/bannerAccorVis.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.bodyMis {
  padding: 5rem 3rem 5rem 3rem;
  background-image: url("assets/bgBodyMis.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.bodyVis {
  padding: 5rem 3rem 5rem 3rem;
  background-image: url("assets/bgBodyVis.png");
  background-repeat: no-repeat;
  background-size: cover;
}

/* footer */
.navTtn {
  z-index: 999;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  border-radius: 0px 0px 25px 25px;
}

ul {
  margin: 0px;
  padding: 0px;
}

.footer-section {
  background: linear-gradient(69.48deg, #005dbf -7.83%, #01a0f6 35%, rgba(97, 255, 246, 0) 231.49%);
  position: relative;
}

.footer-content {
  position: relative;
  z-index: 2;
}

.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  background-size: cover;
  background-position: 100% 100%;
}

.footer-logo {
  margin-bottom: 30px;
}

.footer-logo img {
  max-width: 300px;
}

.footer-text p {
  width: 80%;
  margin-bottom: 14px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  line-height: 28px;
}

.footer-social-icon span {
  color: #fff;
  display: block;
  font-size: 20px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
}

.footer-social-icon a {
  color: #fff;
  font-size: 16px;
  margin-right: 15px;
}

.footer-social-icon i {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
}

.footer-widget-heading h3 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 40px;
  position: relative;
}

.footer-widget-heading h1 {
  color: #fff;
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 40px;
  position: relative;
}

.footer-widget ul li {
  display: inline-block;
  /* float: left; */
  width: 50%;
  margin-bottom: 12px;
}

.footer-widget ul li a {
  color: #878787;
  text-transform: capitalize;
}

.subscribe-form {
  position: relative;
  overflow: hidden;
}

.subscribe-form input {
  width: 100%;
  padding: 14px 28px;
  background: #2e2e2e;
  border: 1px solid #2e2e2e;
  color: #fff;
}

.subscribe-form button i {
  color: #fff;
  font-size: 22px;
  transform: rotate(-6deg);
}

.copyright-area {
  background: #202020;
  padding: 25px 0;
}

.copyright-text p {
  margin: 0;
  font-size: 14px;
  color: #878787;
}

.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}

.footer-menu li a {
  font-size: 14px;
  color: #878787;
}

.borderColorPrimary {
  border: 1px solid #01a0f6;
}

.cardUser {
  background: #ffffff;
  box-shadow: 0px 1px 11px 2px rgba(78, 167, 205, 0.21);
  border-radius: 14px;
}

.selectMultiFormContact {
  margin-right: 3px;
}

.cardWorkComponent {
  min-height: 236px;
  /* max-height: 236px; */
  background-color: #ffffff60;
  box-shadow: 0px 1px 11px 2px rgba(78, 167, 205, 0.21);
  border-radius: 14px;
  border: 3px solid #3a4ed5;
  transition: border-color 0.5s ease-in 0.3s;
}

.cardWorkComponent:hover {
  border-color: #FFB703;
}

.textVacancies {
  color: #01a0f6;
  transition: color 0.5s ease-in 0.3s;
}

.textVacanciesYellow {
  transition: color 0.5s ease-in 0.3s;
  color: #FFB703;
}

.cursorPointer {
  cursor: pointer;
}

.inputFormContactWork {
  background: #ffffff;
}

.inputFormContactWork::placeholder {
  color: #a0a0a050;
}

.formVacant {
  background-image: url("assets/bgForm.svg");
  background-repeat: no-repeat;
  /* background-size: cover; */
  border-radius: 22px 22px 22px 22px;
}

.btnWork {
  background-image: linear-gradient(to right, #005DBF 0%, #01A0F6 51%, #005DBF 100%);
  margin: 10px;
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
  border: 0px;
  transition: background-image 0.5s ease-in 0.3s;
}

.btnWork:hover {
  background-image: linear-gradient(to right, #FFB703 0%, #FFB703 51%, #FFB703 100%);
  /* background: #FFB703; */
  /* background-position: right center; /* change the direction of the change here */
  /* color: #fff;
  text-decoration: none; */
}

.btnAboutsUsTeam {
  border-radius: 32px;
  border: 2px solid #0783FF;
  width: 244px;
}

.titleNav {
  color: #003264;
}

.titleNav:hover{
  color: #01A2F5;
}

.titleNav::after {
  position: relative;
  display: block;
  content: '';
  border-bottom: solid 4px #01A2F5;
  border-radius: 50px;
  left:20%;
  width: 40%;
  transform: scaleX(0);
  transition: transform 200ms ease-in-out;
}

.titleNav:hover:after {
  transform: scaleX(1);
  width: 60%;
}

.circleLeft{
  position: absolute;
width: 1615px;
height: 1568px;
left: -1022px;
top: 361px;
background: linear-gradient(69.48deg, #005DBF -7.83%, #01A0F6 35%, rgba(97, 255, 246, 0) 231.49%);
opacity: 0.2;
filter: blur(148px);
}


.circleRight {
  position:sticky;
  width: 1334px;
  height: 1414px;
  left: 692px;
  /* left: 900px; */
  top: -829px;
  /* degradado */
  background: linear-gradient(69.48deg, #005DBF -7.83%, #01A0F6 35%, rgba(97, 255, 246, 0) 231.49%);
  opacity: 0.1;
  filter: blur(148px);
  /* background-color: #fff; */
}

.telSpanBanner{
  background: #005BB5;
  border-radius: 15px;
  color: white
}

.bioContactButton{
  background-color: #01a0f6;
  color: white;
  border-radius: 5px;
}

.cardContact{
  border-radius: 22px 200px 22px 22px;
}

.cardAboutItemMovilDiv{
  background-color: #ffffff60;
  border-radius: 0px 0px 0px 20px;
}

.cardAboutItemMovil {
  min-height: 200px;
  max-height: 200px;
  min-width: 200px;
  max-width: 200px;
  /* background-color: #ffffff60; */
  margin: 0 auto;


  /* display: ; */
  /* box-shadow: 0px 1px 11px 2px rgba(78, 167, 205, 0.21); */
}

.swal2-popup {
  font-size: 16px !important;
}

.no-border {
  border: 0 !important;
}

/* .rec.rec-arrow {
  border-radius: full;
}
.rec.rec-arrow:hover,
.rec.rec-arrow:focus,
.rec.rec-arrow:active
 {
  background-color: #01a0f6;
  color: white
} */

.rec-dot_active {
  background-color: #01a0f6 !important;
  box-shadow: 0 0 1px 3px #01a0f6 !important;
}
.rec-dot:focus,
.rec-dot:hover {
  box-shadow: 0 0 1px 3px #01a0f6 !important;
}
.rec-dot_active:focus,
.rec-dot_active:hover {
  background-color: #01a0f6 !important;
  box-shadow: 0 0 1px 3px #01a0f6 !important;
}

.rec-arrow {
  /* background-color: var(--blue) !important; */
  background-color: white !important;
  transition: all 0.3s ease;
  font-size: 1.1rem !important;
  color: #01a0f6 !important;
  width: 2rem !important;
  height: 2rem !important;
  min-width: 2rem !important;
  line-height: 2rem !important;
  border: 0 !important;
}

.rec-arrow:hover:not(:disabled) {
  transform: scale(1.2);
}

.text-alignment{
  text-align: start;
}
.text-alignment-justify{
  text-align: justify;
}
.text-alignment-left{
  text-align: right;
}


@media (max-width: 1270px){
  .hiddenDesktop {
    display: flex;
  }

  .cardAboutItem {
    min-height: 150px;
    max-height: 150px;
    min-width: 150px;
    max-width: 150px;
    background-color: #ffffff60;
    margin: 0 auto;
    display: none !important;
  }
    /* box-shadow: 0px 1px 11px 2px rgba(78, 167, 205, 0.21); */

    .cardAboutItemMovil {
      min-height: 180px;
      max-height: 180px;
      min-width: 180px;
      max-width: 180px;
      /* background-color: #ffffff60; */
      margin: 0 auto;
      /* box-shadow: 0px 1px 11px 2px rgba(78, 167, 205, 0.21); */
    }
  


}


/* HEADEEEEEEEER */
/* mediaquery mobile */
@media (max-width: 576px) {
  /* .cardAboutItemMovil {
    display: none !important;
  } */

  .bannerBiowel1 {
    height: 100vh;
    background-image: url("assets/prueba1.svg");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .fs-38{
    font-size: 14px;
  }

  .fs-16-10 {
    font-size: 10px;
  }

  .imgBR {
    border-radius: 20px 20px 0px 0px;
  }

  .fwBold{
    font-weight: normal;
  }

  .fs-20{
    font-size: 12px;
  }

  .fs-22{
    font-size: 14px;
  }

  .fs-26-12{
    font-size: 12px;
  }

  .fs-26 {
    font-size: 16px;
  }

  .fs-32 {
    font-size: 18px;
  }
  .fs-32-30 {
    font-size: 30px;
  }
  .fs-32-16 {
    font-size: 16px;
  }

  .fs-40 {
    font-size: 30px;
  }

  .fs-40-18 {
    font-size: 18px;
  }

  .fs-40-24 {
    font-size: 24px;
  }

  .fs-48 {
    font-size: 24px;
  }

  .fs-60 {
    font-size: 50px;
  }
  .fs-60-24 {
    font-size: 24px;
  }

  .fs-64 {
    font-size: 36px;
  }

  .fs-96 {
    font-size: 32px;
  }
  .fs-96-80 {
    font-size: 40px;
  }
  

  .bannerHome {
    height: 90vh;
    background-image: url("assets/bannerHomeMobile.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .bannerContactMovil {
    height: 40vh;
    background-image: url("assets/contact.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .bannerAbout {
    height: 50vh;
    background-image: url("assets/bannerAbout.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

  }

  .bannerService {
    height: 60vh;
    background-image: url("assets/bannerService.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .bgServiceProdu {
    height: 66vh;
    background-image: url("assets/bgServiPro.svg");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .bannerWorkWithUs {
    height: 60vh;
    background-image: url("assets/bannerWorkWithUs.png");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .ttnCompanyDiv { order: 3; }
  .contactDiv { order: 1; }
  .aboutDiv { order: 2; }

  .cardContact{
    border-radius: 22px 20px 22px 22px;
  }

  .w-70 {
    width: 75%;
  }
  .bgFrom {
    background-image: url("assets/bgForm.svg");
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50px 50px 0px 0px;
  }

  .imgBRL300 {
    border-radius: 0px 0px 40px 40px;
    /* height: 300px; */
    height: 40vh;
    width: 100%;
  }
  
  .responsiveSectPrivacy{
    display: flex; 
    flex-flow: column; 
  }


  .privacyDiv { order: 2; }
  .imgDiv { order: 1; }

  .hiddenMobile {
    display: none;
  }
  .hiddenDesktop {
    display: none;
  }

  .bannerProducts {
    height: 60vh;
    background-image: url("assets/bannerProducts.svg");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .responsiveSectTeam{
    display: flex; 
    flex-flow: column; 
  }

  .ttnTeamTextDiv { order: 2; }
  .imgDivWork { order: 1; }

  .imgBRL300Woman {
    border-radius: 0px 0px 40px 40px;
    /* height: 300px; */
    /* height: 40vh; */
    width: 100%;
  }

  .fs-24 {
    font-size: 12px;
  }

  .fs-24-18 {
    font-size: 18px;
  }

  .fs-26-20 {
    font-size: 20px;
  }

  .fs-54 {
    font-size: 24px;
  }

  .text-alignment{
    text-align: center;
  }
  .text-alignment-justify{
    text-align: center;
  }
  .text-alignment-left{
    text-align: center;
  }
}

